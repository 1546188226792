<template>
  <div
    v-bind="$attrs"
    :class="[{ 'colored-row': !isEven, 'current-user': isCurrentUser }]"
  >
    <div class="row-column user-position">{{ member.rank }}</div>
    <div
      class="row-column user-details"
      :class="{ 'has-spartan-plus': member.spartan_plus }"
    >
      <div class="avatar-section" @click="openPublicProfile">
        <div
          class="captain-icon"
          v-if="member.role === 'captain' || member.role === 'owner'"
        >
          <i class="material-icons" v-if="member.role === 'captain'">
            star_rate
          </i>
          <v-icon v-if="member.role === 'owner'">mdi-crown</v-icon>
        </div>
        <div
          v-if="!member.avatar"
          class="default-avatar"
          :style="{ 'background-color': defaultAvatarColor }"
        >
          {{ getAvatarLetters }}
        </div>
        <div v-if="member.avatar" class="user-avatar">
          <img :src="member.avatar" alt="photo" />
        </div>
      </div>
      <div class="user-info" @click="openPublicProfile">
        <span class="user-name">{{ memberName }}</span>
        <span class="user-subline" v-if="member.spartan_plus">Spartan EDGE</span>
      </div>
    </div>
    <div class="row-column user-stats">
      <span>{{ stats.events || "--" }}</span>
    </div>
    <div class="row-column user-stats">
      {{ stats.distance_km ? stats.distance_km + " km" : "--" }}
    </div>
    <div class="row-column user-stats">
      {{ topFinish || "--" }}
    </div>
    <div class="row-column user-stats">
      {{ stats.trifectas || "--" }}
    </div>
    <div class="row-column more-button">
      <v-menu transition="slide-y-transition" bottom left>
        <template v-slot:activator="{ on, attrs }" v-if="showMoreButton">
          <i class="material-icons-outlined" v-bind="attrs" v-on="on"
            >more_horiz</i
          >
        </template>
        <div class="member-menu">
          <div
            v-if="isOwner && member.role === 'member' && !isCurrentUser"
            class="menu-line cursor-pointer"
            @click="openConfirmDialog('promote-captain')"
          >
            <i class="material-icons"> star </i>
            <span>{{ $t("promote_to_captain") }}</span>
          </div>
          <div
            v-if="isOwner && member.role === 'captain' && !isCurrentUser"
            class="menu-line cursor-pointer"
            @click="openConfirmDialog('revoke-captain')"
          >
            <i class="material-icons"> star </i>
            <span>{{ $t("revoke_captainship") }}</span>
          </div>
          <div
            v-if="isOwner && !isCurrentUser"
            class="menu-line cursor-pointer"
            @click="openConfirmDialog('pass-ownership')"
          >
            <v-icon>mdi-crown</v-icon>
            <span>{{ $t("pass_team_ownership") }}</span>
          </div>
          <div
            v-if="
              (isOwner || isCaptain) &&
              !isCurrentUser &&
              member.role !== 'owner'
            "
            class="menu-line cursor-pointer"
            @click="openConfirmDialog('remove-athlete')"
          >
            <i class="material-icons"> delete </i>
            <span>{{ $t("remove_athlete") }}</span>
          </div>
          <div
            v-if="isCurrentUser"
            class="menu-line cursor-pointer"
            @click="leaveCurrentTeam"
          >
            <i class="material-icons"> delete </i>
            <span>{{ $t("leave_team") }}</span>
          </div>
        </div>
      </v-menu>
    </div>
  </div>
</template>
<script>
import { capitalize } from "lodash";
import { mapGetters } from "vuex";
import localizeResolver from "@/common/mixins/localizeResolver";

export default {
  mixins: [localizeResolver],
  data: () => ({
    colors: [
      "#F4741D",
      "#6DA286",
      "#8F34C1",
      "#2361A7",
      "#84BD00",
      "#CF1019",
      "#FFB800",
    ],
    showLeaveDialog: false,
  }),
  props: ["member", "rowIndex", "currentRole", "searchState"],
  computed: {
    ...mapGetters(["userData", "specificLinks"]),
    isEven() {
      if (this.searchState) return this.rowIndex % 2 !== 0;
      return this.member.rank % 2 == 0;
    },
    getAvatarLetters() {
      return (
        this.member?.first_name?.charAt(0) + this.member?.last_name?.charAt(0)
      );
    },
    isOwner() {
      return this.currentRole === "owner";
    },
    isCaptain() {
      return this.currentRole === "captain";
    },
    isCurrentUser() {
      return this.userData?.id === this.member?.id;
    },
    defaultAvatarColor() {
      return this.colors[Math.floor(Math.random() * this.colors.length)];
    },
    stats() {
      if (!this.member.stats) return {};
      return this.member.stats;
    },
    showMoreButton() {
      return (
        this.currentRole === "owner" ||
        (this.currentRole === "captain" && this.member.role !== "owner") ||
        this.isCurrentUser
      );
    },
    topFinish() {
      if (
        !this.stats?.top_finish_format?.place &&
        !this.stats?.top_finish_format?.group
      )
        return "";
      return `${this.stats.top_finish_format.place} ${
        this.stats.top_finish_format.group || ""
      }`;
    },
    memberName() {
      return (
        capitalize(this.member?.first_name) +
        " " +
        capitalize(this.member?.last_name?.charAt(0)) +
        "."
      );
    },
  },
  methods: {
    openConfirmDialog(action) {
      this.$emit("show-confirm", { member: this.member, action });
    },
    async leaveCurrentTeam() {
      console.log("leave team");
      this.$emit("leave-team");
    },
    openPublicProfile() {
      let homeUrl = this.localizeConfig?.homeUrl;
      let windowReference = window.open();
      windowReference.location = `${homeUrl}/race/profile/${this.member.id}`;
    },
  },
};
</script>
<style lang="scss" scoped>
@mixin defaultText {
  font-size: 12px;
  line-height: 15px;
  font-weight: 600;
}
@mixin textBold {
  font-size: 12px;
  line-height: 15px;
  font-weight: bold;
}
@mixin defaultAvatar($size) {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 100px;
  height: $size;
  width: $size;
  font-weight: bold;
}
.member-menu {
  background: #191919;
  border: 0.5px solid #4f4f4f;
  border-radius: 18px;
  padding: 14px;
  width: 278px;
  box-shadow: none;
  .menu-line {
    height: 46px;
    border-radius: 8px;
    font-size: 14px;
    line-height: 14px;
    color: #ffffff;

    display: flex;
    flex-direction: row;
    align-items: center;

    padding: 0 0 0 14px;

    transform: perspective(1px) translateZ(0);
    transition-duration: 0.5s;
    i {
      color: rgba(255, 255, 255, 0.4);
      margin-right: 18px;
    }
  }
  .menu-line:hover {
    background: rgba(255, 255, 255, 0.08);
    transform: scale(1.03);

    i {
      color: #ffffff;
      transition: color 0.5s linear;
    }
    // transition: background-color 0.2s linear;
  }
}
::v-deep(.v-menu__content) {
  box-shadow: none;
}

.row-column {
  height: 70px;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.user-position {
  @include textBold;
  justify-content: flex-end;
}
.user-details {
  padding-left: 24px;
  .avatar-section {
    position: relative;
    cursor: pointer;
    outline: none;
    .captain-icon {
      position: absolute;
      top: -3px;
      right: 11px;
      height: 16px;
      width: 16px;
      border-radius: 100px;
      background-color: #cf1019;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      i {
        font-size: 12px;
        margin-left: 1px;
      }
    }
  }
  .default-avatar {
    @include defaultAvatar(28px);
    margin-right: 16px;
  }
  .user-avatar {
    img {
      @include defaultAvatar(28px);
    }
    margin-right: 16px;
  }
  .user-info {
    display: flex;
    flex-direction: column;
    cursor: pointer;
    outline: none;
    .user-name {
      @include textBold;
      margin-bottom: 2px;
    }
    .user-subline {
      font-size: 9px;
      line-height: 11px;
      font-weight: 600;
      text-transform: capitalize;
      color: #cf1019;
    }
  }

  &.has-spartan-plus {
    .user-avatar {
      img {
        border: 2px solid #cf1019;
      }
    }
    .default-avatar {
      border: 2px solid #cf1019;
    }
  }
}
// .user-position {
//   padding-left: 25px;
// }
.user-stats {
  @include defaultText;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #d0d0d0;
}
.more-button {
  padding-left: 8px;
  // padding-right: 24px;
  cursor: pointer;
  outline: none;
  i {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    // font-size: 17px;
    color: #717171;
    height: 30px;
    width: 30px;
    border-radius: 100px;
    transition: all 200ms ease-in-out;
  }
  i:hover {
    background-color: #191919;
  }
}
.colored-row {
  .row-column {
    background-color: #121212;
  }
}
.current-user {
  .row-column {
    background-color: #cf102d;
  }
  .user-details {
    .avatar-section {
      .captain-icon {
        background-color: #ffffff;
        i {
          color: #cf1019;
        }
      }
      .default-avatar {
        border: none;
      }
      .user-avatar img {
        border: none;
      }
    }
    .user-info {
      .user-subline {
        color: #ffffff;
      }
    }
  }
  .more-button {
    i {
      color: #e27081;
    }
    i:hover {
      background-color: #d42842;
    }
  }
}
</style>
