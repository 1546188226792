<template>
  <div class="invite-member">
    <div class="close-invite">
      <i class="material-icons" @click="close"> close </i>
    </div>
    <div class="invite-title">
      {{ $t("invite_friends_to_join") }}
    </div>
    <div class="category-dropdown" v-if="tickets.length !== 0">
      <div class="category-label">{{ $t("select_category") }}</div>
      <template v-if="useDeviceSelect">
        <div class="mobile-select">
          <select v-model="selectedCategory">
            <option
              v-for="(ticket, index) in tickets"
              v-bind:value="ticket"
              :key="index"
            >
              {{ ticket.reg_choice_name }}
            </option>
          </select>
        </div>
      </template>
      <template v-if="!useDeviceSelect">
        <v-menu>
          <template v-slot:activator="{ on, attrs }">
            <div class="category-input" v-on="on" v-bind="attrs">
              <div>{{ selectedCategory.reg_choice_name }}</div>
              <span class="material-icons"> arrow_drop_down </span>
            </div>
          </template>
          <div class="category-menu">
            <span class="material-icons menu-icon"> arrow_drop_down </span>
            <template v-for="(ticket, index) in tickets">
              <div
                :key="index"
                @click="selectTicket(ticket)"
                class="category-menu-item"
              >
                <span>{{ ticket.reg_choice_name }}</span>
              </div>
            </template>
          </div>
        </v-menu>
      </template>
    </div>
    <div class="invite-link">
      <div class="link-label">{{ $t("share_the_invitation_link") }}</div>
      <div class="link-input">
        <input
          type="text"
          v-model="link"
          name="share link"
          id="share-link"
          readonly
          @focus="copyLink"
        />
        <div class="input-button" @click="copyLink">
          <span v-if="!linkCopied">{{ $t("copy") }}</span>
          <span v-if="linkCopied">{{ $t("copied") }}</span>
        </div>
      </div>
    </div>
    <div class="dialog-buttons">
      <mem-button @click="close">{{ $t("done") }}</mem-button>
    </div>
  </div>
</template>
<script>
import { uniqBy, lowerCase } from "lodash";
import { mapGetters } from "vuex";
export default {
  components: {
    "mem-button": () => import("@/components/base/BaseButton.vue"),
  },
  data: () => ({
    linkCopied: false,
    selectedCategory: "",
  }),
  props: {
    source: String,
    inviteLink: {
      type: String,
      required: true,
    },
    teamTickets: {
      type: Array,
      required: false,
    },
    eventId: [String, Number],
    eventCountry: String,
  },
  computed: {
    ...mapGetters(["team"]),
    link() {
      if (this.source === "TeamPage") return this.inviteLink;

      let { reg_choice_ticket_type_id } = this.selectedCategory || {};

      return `${this.inviteLink}\n${location.origin}/${lowerCase(
        this.eventCountry
      )}/checkout?goToCheckout=1&cartTasks[ticketTypeId_${reg_choice_ticket_type_id}][typeId]=${reg_choice_ticket_type_id}&cartTasks[ticketTypeId_${reg_choice_ticket_type_id}][eventId]=${
        this.eventId
      }&cartTasks[ticketTypeId_${reg_choice_ticket_type_id}][quantity]=1&utm_content=race&utm_source=account&utm_medium=account&utm_campaign=2024_team_invite`;
    },
    tickets() {
      return uniqBy(this.teamTickets, "reg_choice_ticket_type_id");
    },
    useDeviceSelect() {
      return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
      );
    },
  },
  methods: {
    close() {
      this.$emit("close-invite");
    },
    // copyLink() {
    //   if (this.linkCopied) return;
    //   let teamLink = document.querySelector("#share-link");
    //   teamLink.select();

    //   document.execCommand("copy");
    //   this.linkCopied = true;
    //   setTimeout(() => {
    //     this.linkCopied = false;
    //   }, 2000);
    // },

    async copyLink() {
      await navigator.clipboard.writeText(this.link);

      this.linkCopied = true;
      setTimeout(() => {
        this.linkCopied = false;
      }, 2000);
    },

    selectTicket(ticket) {
      this.selectedCategory = ticket;
    },
  },
  created() {
    this.selectedCategory = this.tickets[0];
  },
  mounted() {},
};
</script>
<style lang="scss" scoped>
@mixin inputLabel() {
  font-size: 12px;
  line-height: 15px;
  letter-spacing: 0.02em;
  font-weight: 600;
  text-transform: uppercase;
  color: #9d9d9d;
}
::v-deep(.v-menu__content) {
  border-radius: 4px;
}
.category-menu {
  background: #ffffff;
  color: #000000;
  padding: 5px;
  position: relative;
  .menu-icon {
    position: absolute;
    top: 8px;
    right: 12px;
    transform: rotate(180deg);
  }
  .category-menu-item {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 38px;
    border-radius: 4px;
    padding-left: 10px;
    margin-bottom: 5px;
    font-size: 12px;
    line-height: 15px;
    font-weight: 600;
    @include cursorPointer;
  }
  .category-menu-item:hover {
    background-color: #f2f2f2;
  }
}
.invite-member {
  position: relative;
  background: #ffffff;
  color: #121212;
  padding: 40px 30px 35px 35px;
  border-radius: 10px;
  @media screen and (max-width: 499px) {
    padding: 30px 12px 20px 12px;
  }
  .close-invite {
    position: absolute;
    top: 8px;
    right: 8px;
    display: flex;
    i {
      color: #000000;
      cursor: pointer;
      outline: none;
      font-size: 30px;
    }
  }
  .invite-title {
    font-size: 20px;
    line-height: 24px;
    font-weight: bold;
    margin: 0 0 32px 0;
  }
  .category-dropdown {
    .category-label {
      @include inputLabel;
      margin-bottom: 12px;
    }
    .category-input {
      height: 40px;
      background: #f2f2f2;
      border-radius: 4px;
      // margin-right: 22px;
      margin-bottom: 32px;
      padding-left: 12px;
      display: grid;
      grid-template-columns: 1fr 36px;
      align-items: center;
      @include cursorPointer;

      font-size: 12px;
      line-height: 15px;
      font-weight: 600;
    }
    .mobile-select {
      display: grid;
      margin-bottom: 22px;
      select {
        background-color: #f2f2f2;
        color: #121212;
        border-radius: 4px;
        height: 40px;
        // margin-right: 22px;
        font-size: 12px;
        padding-left: 12px;
        line-height: 15px;
        font-weight: 600;
        width: 100%;
        outline: none;
      }
    }
  }
  .invite-link {
    margin-bottom: 45px;
    @media screen and (max-width: 499px) {
      margin-bottom: 38px;
    }
    .link-label {
      @include inputLabel;
      margin-bottom: 12px;
    }
    .link-input {
      background: #f2f2f2;
      height: 40px;
      border-radius: 4px;
      display: grid;
      grid-template-columns: 1fr 92px;
      @media screen and (max-width: 499px) {
        grid-template-columns: 1fr 72px;
      }
      // margin-right: 22px;
      input {
        color: #919191;
        font-size: 12px;
        line-height: 15px;
        font-weight: 600;
        outline: none;
        padding-left: 12px;
        padding-right: 12px;

        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
      }
      .input-button {
        border-left: 1px solid #c2c2c2;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;

        cursor: pointer;
        outline: none;

        font-size: 12px;
        line-height: 15px;
        text-transform: uppercase;
        font-weight: bold;
        letter-spacing: 0.04em;
      }
    }
  }
  .dialog-buttons {
    button {
      height: 36px;
      min-width: 80px;
      font-size: 12px;
      line-height: 15px;
    }
  }
}
</style>